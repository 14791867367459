/**
 * Renders a single column section that contains a section heading,
 * textblock and an image displayed below
 */

import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import TextBlock from '../../molecules/TextBlock/TextBlock';
import Image from '../../atoms/Image/Image';
import sanityImage from '../../../utils/sanityImage';
import { pageDimensions, unitConverter as uc } from '../../../styles/base';
import Section from '../../molecules/Section/Section';
import ProductScreenAnimation from '../../molecules/ProductScreenAnimation/ProductScreenAnimation';

const OneColumnSection = ({
  backgroundImage,
  className,
  imageOrProductScreen,
  sectionStyles,
  textBlock,
}) => {
  // TODO: remove after we migrate content from image field to
  // imageOrProductScreen field this overrides the deprecated
  // image in case the content admin has entered both
  let resolvedImage;

  if (
    imageOrProductScreen &&
    imageOrProductScreen.length > 0 &&
    imageOrProductScreen[0]._type === 'csodImage'
  ) {
    [resolvedImage] = imageOrProductScreen;
  } else if (
    imageOrProductScreen &&
    imageOrProductScreen.length > 0 &&
    imageOrProductScreen[0]._type === 'productScreenAnimation'
  ) {
    resolvedImage = null;
  }

  const sectionCss = css`
    width: 100%;
  `;

  const textBlockCss = css`
    max-width: ${uc('620px')};
    margin: 0 auto ${uc('40px')};
  `;

  const backgroundCss = css`
    width: 100%;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: ${pageDimensions.largeDesktopWidth};
  `;

  const imageCss = css`
    position: relative;
    z-index: 1;
    vertical-align: bottom;
  `;

  const productScreenAnimationCss = css`
    margin: 0 auto;
  `;

  const bgImage = {
    backgroundImage: `url(${sanityImage(backgroundImage)
      .auto('format')
      .width(pageDimensions.rawLargeDesktopWidth)
      .fit('max')
      .url()})`,
  };

  const productScreenExists =
    imageOrProductScreen &&
    imageOrProductScreen.length > 0 &&
    imageOrProductScreen[0]._type === 'productScreenAnimation';

  const resolvedImageExists =
    resolvedImage && Object.keys(resolvedImage).length > 0;

  return (
    <Section
      sectionStyles={sectionStyles}
      css={sectionCss}
      className={className}
    >
      {textBlock && (textBlock.headingText || textBlock.description) && (
        <TextBlock css={textBlockCss} {...textBlock} />
      )}
      <div css={backgroundCss} style={bgImage}>
        {productScreenExists && (
          <ProductScreenAnimation
            css={productScreenAnimationCss}
            {...imageOrProductScreen[0]}
          />
        )}
        {resolvedImageExists && (
          <Image
            css={imageCss}
            src={sanityImage(resolvedImage)
              .auto('format')
              .width(1800)
              .fit('max')
              .url()}
            alt={resolvedImage.alt}
          />
        )}
      </div>
    </Section>
  );
};

OneColumnSection.propTypes = {
  backgroundImage: PropTypes.shape({}),
  className: PropTypes.string,
  imageOrProductScreen: PropTypes.arrayOf(
    PropTypes.shape({ _type: PropTypes.string })
  ),
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  textBlock: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
};

OneColumnSection.defaultProps = {
  backgroundImage: {},
  className: '',
  imageOrProductScreen: null,
  sectionStyles: {},
  textBlock: [],
};

export default OneColumnSection;
